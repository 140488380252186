<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="400">

      <v-card flat tile>

        <v-card-title>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text class="px-10 py-4 text-center">

          <v-icon class="mb-5" color="red" size="95">mdi-close-circle</v-icon>

          <h2 class="mb-5 grey--text text--darken-2">Aucun résultat trouvé !</h2>

          <p class="fs-14">
            Le code saisi est incorrect, veuillez scanner un code QR valide ou saisir un code valide.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        open() {
            this.dialog = true
        }
    }
}
</script>

<style scoped>

</style>