<template>
  <div>

    <Navbar/>
    <Overlay :overlay="isLoading"/>
    <NewUserDialog ref="newUserDialog" @refresh="getStat"/>
    <ResultDialog ref="resultDialog" @refresh="[getStat(),code='']"/>
    <NoResultDialog ref="noResultDialog"/>

    <v-container fluid>

      <v-row justify="center">

        <v-col cols="12" lg="6">

          <div class="text-center py-3">
            <v-btn color="primary"
                   depressed
                   fab
                   large
                   @click="$refs.newUserDialog.open()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>


          <v-card class="shadow rounded-lg mt-3" to="/list">
            <v-card-text>
              <v-row align="center">
                <v-col cols="10">
                  <div class="d-flex justify-space-between align-center mb-3">
                    <div class="font-weight-medium">{{ stat.percentage }}%</div>
                    <div>
                      <span class="font-weight-medium">{{ stat.done }}</span> terminés de {{ stat.total }}
                    </div>
                  </div>
                  <v-progress-linear :value="stat.percentage"
                                     color="primary"
                                     height="10"
                                     rounded
                                     striped></v-progress-linear>
                </v-col>
                <v-col class="text-center" cols="2">
                  <v-btn color="primary" depressed fab small>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="shadow rounded-lg mt-3" flat>

            <v-card-title class="pa-0">
              <v-tabs v-model="tab"
                      background-color="transparent"
                      centered
                      class="rounded"
                      color="primary"
                      icons-and-text
                      show-arrows
                      slider-size="3"
              >
                <v-tab @click="scan = false">
                  Lecteur QR code
                  <v-icon>mdi-cable-data</v-icon>
                </v-tab>
                <v-tab @click="scan = true">
                  Scaner par caméra
                  <v-icon>mdi-camera-outline</v-icon>
                </v-tab>
              </v-tabs>

            </v-card-title>

            <v-divider/>

            <v-card-text>

              <v-tabs-items v-model="tab" touchless>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-lg-10 px-4">

                      <p class="font-weight-medium">
                        Code utilisateur <span class="red--text">*</span>
                      </p>

                      <v-textarea v-model="code"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                  hide-details
                                  outlined
                                  placeholder="Placez le curseur ici, et scannez le code QR..."
                                  rows="2">

                      </v-textarea>

                      <div class="d-flex mt-3 align-center">

                        <v-spacer/>

                        <v-btn :dark="!!code"
                               :disabled="!code"
                               color="primary"
                               depressed
                               rounded
                               @click="show">
                          <v-icon left>mdi-rotate-left</v-icon>
                          Vérifier
                        </v-btn>

                      </div>

                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-lg-10 px-4">
                      <div v-if="scan">
                        <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs-items>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import { StreamBarcodeReader } from "vue-barcode-reader";
import {HTTP} from "@/http-common";
import Navbar from "@/views/home/components/Navbar.vue";
import NewUserDialog from "@/views/home/components/NewUserDialog.vue";
import ResultDialog from "@/views/home/components/ResultDialog.vue";
import NoResultDialog from "@/views/home/components/NoResultDialog.vue";

export default {
    components: {
        Navbar,
        ResultDialog,
        NoResultDialog,
        NewUserDialog,
        StreamBarcodeReader
    },
    data() {
        return {
            tab: null,
            scan: false,
            isLoading: false,
            btnLoading: false,
            code: null,

            stat: {
                done: 0,
                total: 0,
                percentage: 0,
            },
        }
    },
    methods: {
        show() {
            this.isLoading = true
            HTTP.get('/database/show', {
                params: {
                    code: this.code
                }
            }).then(res => {
                this.isLoading = false
                if (res.data.data) {
                    this.$refs.resultDialog.open(res.data.data)
                } else {
                    this.$refs.noResultDialog.open()
                }
                this.refreshScan()
            }).catch(err => {
                this.refreshScan()
                this.isLoading = false
                console.log(err)
            })
        },
        onDecode(result) {
            this.code = result
            this.show()
        },
        refreshScan() {
            let _vm = this
            _vm.scan = false
            setTimeout(function () {
                _vm.scan = true
            }, 200)
        },
        getStat() {
            this.isLoading = true
            HTTP.get('/database/stat').then(res => {
                this.isLoading = false
                this.stat = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getStat()
        this.scan = true
    }
}
</script>

<style scoped>

</style>