<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500">

      <v-card :disabled="saveLoading" :loading="saveLoading" flat tile>

        <v-card-title>
          Nouveau utilsateur
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-text-field v-model="form.name"
                        :dense="!$vuetify.breakpoint.mobile"
                        :error-messages="errors.name"
                        label="Nom complet *"
                        outlined
                        prepend-inner-icon="mdi-account"
          >
          </v-text-field>

          <v-text-field v-model="form.phone"
                        :dense="!$vuetify.breakpoint.mobile"
                        :error-messages="errors.phone"
                        counter="9"
                        label="Numéro de téléphone *"
                        outlined
                        prefix="+213"
                        prepend-inner-icon="mdi-phone"
                        type="number">
          </v-text-field>

          <v-btn :disabled="form.phone.length !== 9 || !form.name"
                 :loading="saveLoading"
                 block
                 color="primary"
                 depressed
                 large
                 rounded
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {
                name: '',
                phone: '',
            }
        }
    },
    methods: {
        open() {
            this.errors = {}
            this.dialog = true
            this.form = {
                name: '',
                phone: '',
            }
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: '/database/create',
                method: 'POST',
                data: {
                    name: this.form.name,
                    phone: '213' + this.form.phone,
                },
            }).then(() => {
                this.dialog = false
                this.$successMessage = 'Cet utilisateur a été enregistré avec succès.'
                this.saveLoading = false
                this.$emit('refresh')
            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>