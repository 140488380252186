<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="400">

      <v-card :disabled="saveLoading" :loading="saveLoading" flat tile>

        <v-card-title>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text class="px-10 py-4 text-center">

          <v-icon class="mb-5" color="success" size="95">mdi-check-circle</v-icon>

          <h2 class="mb-5 grey--text text--darken-2">Résultat !</h2>

          <p class="fs-14 mb-7">
            Vous pouvez changer le numéro de téléphone et lui envoyer le sms
          </p>

          <v-text-field v-model="form.name"
                        :dense="!$vuetify.breakpoint.mobile"
                        disabled
                        label="Nom d'utilisateur"
                        outlined
                        prepend-inner-icon="mdi-account"
                        shaped></v-text-field>

          <v-text-field v-model="form.phone"
                        v-number
                        :dense="!$vuetify.breakpoint.mobile"
                        :error-messages="errors.phone"
                        counter="9"
                        label="Numéro de téléphone *"
                        outlined
                        prefix="+213"
                        prepend-inner-icon="mdi-phone"
                        type="number">
          </v-text-field>

          <v-btn :disabled="form.phone.length !== 9"
                 :loading="saveLoading"
                 block
                 color="success"
                 depressed
                 large
                 rounded
                 @click="send">
            <v-icon left>mdi-send</v-icon>
            Envoyer le sms
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            saveLoading: false,
            errors: {},
            form: {
                phone: '',
                code: '',
                name: '',
            }
        }
    },
    methods: {
        open(result) {
            this.form = {
                phone: result.phone.slice(3),
                code: result.code,
                name: result.name,
            }
            this.errors = {}
            this.dialog = true
        },
        send() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: '/database/send-sms',
                method: 'POST',
                data: {
                    phone: '213' + this.form.phone,
                    code: this.form.code,
                },
            }).then(() => {
                this.dialog = false
                this.$successMessage = 'Le message sms a été envoyé avec succès.'
                this.saveLoading = false
                this.$emit('refresh')
            }).catch(err => {

                this.saveLoading = false

                if (err.response.status === 500) {
                    this.$errorMessage = "Le SMS n'a pas été envoyé. Une erreur de serveur s'est produite"
                }

                if (err.response.status === 422) {
                    this.errors = err.response.data.errors
                }

                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>