<template>
  <div>
    <v-card :loading="isLoading" class="shadow" tile>
      <v-container class="pa-0">
        <v-card-title>
          <img :src="require('@/assets/logo.jpg')"
               alt="logo"
               width="100">
          <v-spacer/>
          <v-btn color="primary" depressed fab small @click="logout">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-card-title>
      </v-container>
    </v-card>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    name: "Navbar",
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        logout() {
            this.isLoading = true
            HTTP.post('logout').then(() => {
                this.isLoading = false
                this.$store.dispatch('user', {})
                this.$store.dispatch('isLoggedIn', false)
                this.$router.go(0)
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>

</style>